const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: "full",
    theme: "slateDark1",
    bgImgURL: ""
  },
  topbar: {
    show: true,
    fixed: true,
    theme: "whiteBlue"
  }
};

export default Layout1Settings;
